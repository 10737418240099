<template>
  <div class="crm f-flex">
    <div class="crm-name">通知公告</div>
  </div>

  <!-- <div class="s-box f-flex">
    <div class="s-t">時间：</div>
    <div class="f-plr10">
      <select class="ant-select">
        <option value="1">1天內</option>
        <option value="7">7天內</option>
        <option value="15">15天內</option>
        <option value="30">30天內</option>
      </select>
    </div>
    <button class="btn-blue">查询</button>
  </div> -->

  <div class="table-con">
    <table class="m-table" >
      <tr style="display: none;">
        <th style="width: 40px; background:rgba(0,0,0,0.1) !important; color:#000;">类别</th>
        <th style="width: 150px; background:rgba(0,0,0,0.1) !important; color:#000;">標題</th>
        <th style="width: 60px; background:rgba(0,0,0,0.1) !important; color:#000;">發佈時間</th>
      </tr>

      <tr
        v-for="(item, index) in records.data"
        :key="index"
        @click="openModalNoticeEvent(item)"
      >
        <!-- <td style="width: 40px">{{ item.keyword }}</td> -->
        <td style="width: 150px; text-align: left">{{ item.title }}</td>
        <td style="width: 60px">
          {{ formatTime(item.gmtCreate, "YYYY-MM-DD HH:mm:ss") }}
        </td>
      </tr>
      <tr v-if="records.total == 0">
        <td style="width: 100%" colspan="3">暫無數據</td>
      </tr>
    </table>
    <XtxPagination
      v-if="records.total > 0"
      :current-page="query.pageNo"
      :page-size="query.pageSize"
      :total="records.total"
      @current-change="pageChangeEvent"
    />
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  nextTick,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "LhcLog",
  setup() {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    let query = reactive({
      pageNo: 1,
      pageSize: 20,
      dto: {
        categoryId: "",
      },
    });
    let records = reactive({
      total: 0,
      data: [],
    });
    onMounted(() => {
      nextTick(() => {
        requestNoticePage();
      });
    });

    //獲取公告列表
    const requestNoticePage = () => {
      loading.value = true;
      store
        .dispatch("game/requestPageNoticeAction", query)
        .then((res) => {
          loading.value = false;
          if (res && res.code == 0) {
            records.data = res.res.rows;
            records.total = res.res.total;
          } else {
            records.data = [];
            records.total = 0;
          }
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const pageChangeEvent = ($event) => {
      query.pageNo = $event;
      requestNoticePage();
    };

    const selectChangeEvent = ($event) => {
      query.dto.betType = $event.target.value;
      query.pageNo = 1;
      requestNoticePage();
    };

    //格式化時间
    const formatTime = (time, format) => {
      return getCurrentInstance()
        .appContext.config.globalProperties.$moment(time)
        .format(format);
    };
    const mathFloor = (num, multiple) => {
      return Math.floor(num * multiple) / multiple;
    };
    //公告弹窗
    const openModalNoticeEvent = (_item) => {
      if (_item) {
        router.push(`/game/notice/detail?id=${_item.id}`);
      }
    };
    return {
      loading,
      records,
      query,
      formatTime,
      pageChangeEvent,
      selectChangeEvent,
      mathFloor,
      openModalNoticeEvent,
    };
  },
};
</script>